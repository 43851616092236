.content {
  padding: 24px;
  border-radius: 5px;
  overflow: hidden;
  // background-image: url('/images/planbowl/create_top_bottom_bg.webp');
  background-image: url('https://res-front.pumpsoul.com/pb-prod/image/create_top_bottom_bg.webp');
  background-size: auto 100%;
  background-position: top center;
  background-repeat: no-repeat;

  > .topTitle {
    font-size: 26px;
    color: var(--app-text-color);
  }

  > .topSubTitle {
    margin-top: 32px;
    font-size: 20px;
    font-weight: 500;
    color: var(--app-text-color);
  }

  > .topDesc {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 400;
    color: var(--app-text-color);
  }

  > .infoContent {
    padding: 20px 0;

    .inputPlaceholder,
    .selectContent {
      min-width: 70%;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .radioContent {
      .radioContainer {
        margin-bottom: 20px;
      }

      .textArea {
        padding: 16px;
        resize: none;
        height: 172px;
      }
    }

    .active::placeholder {
      color: var(--app-text-color);
    }

    .bottomSelectContent {
      background-color: #eee;
      padding: 5px;
      border-radius: 5px;
      // position: absolute;
      // top: 50px;
      left: 0;
      width: 100%;

      > span {
        font-size: 14px;
        display: block;
        padding: 5px;
        cursor: pointer;
        border-radius: 5px;
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }

    // .preIcon::before {
    //   content: url('/images/planbowl/people-icon.webp');
    //   display: block;
    //   width: 20px;
    //   height: 20px;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // }
  }

  > .bottomContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > .progressContent {
      line-height: 1;

      > .desc {
        display: inline-block;
        font-size: 14px;
        color: #999;
        padding-bottom: 5px;
      }

      > .progressStep {
        display: flex;
        > .indexDiv {
          width: 50px;
          height: 5px;
          background: #eee;
          margin-left: 5px;

          &:first-child {
            margin-left: 0;
          }
        }

        > .indexDiv.active {
          background: var(--app-primary-color);
          margin-left: 0;
        }
      }
    }

    > .nextContainer {
      display: flex;

      > .preIcon,
      > .nextIcon {
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        background: var(--app-primary-color);
        color: var(--other-text-color);

        min-width: 44px;
        height: 44px;
        margin-right: 8px;
        padding: 0 5px;

        &:last-child {
          margin-right: 0;
        }
      }

      > .nextView {
        width: 200px;
        padding: 0 20px;
      }

      > .preIcon {
        background: #222222;
      }
      .disableBtn {
        background: #f2f2f2;
        color: #999;
        cursor: auto;
      }
      > .disable {
        background: #f2f2f2;
        color: #999;
        cursor: auto;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .content {
    padding: 15px;

    > .topTitle {
      font-size: 20px;
      color: var(--app-text-color);
    }
    .bottomContainer {
      .progressContent {
        .progressStep {
          .indexDiv {
            width: 35px;
          }
        }
      }

      .nextContainer {
        .nextView {
          width: 100px;
        }
      }
    }
    > .topSubTitle {
      margin-top: 15px;
      font-size: 16px;
    }

    > .topDesc {
      margin-top: 10px;
      font-size: 14px;
    }

    > .infoContent {
      padding: 15px 0;

      .selectContent {
        margin-bottom: 15px;
      }
    }
  }
}

