.content {
  display: flex;
  flex-direction: column;
  padding: 52px 28px 44px 28px;

  .tabs {
    position: relative;
    display: flex;
    width: 276px;
    height: 42px;
    background: #e7f2e9;
    margin: 0 auto;
    border-radius: 42px;

    .tabsTip {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 999;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      background: #ed8133;
      padding: 0 12px;
      border-radius: 28px;
      transform: translate(40%, -60%);
      color: #fff;
    }
    .tab {
      position: relative;
      flex: 1;
      line-height: 42px;
      text-align: center;
      border-radius: 42px;
      font-size: 14px;
      color: var(--app-primary-color);
      font-weight: 600;
      span {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 99;
        cursor: pointer;
        transition: all 0.6s ease-in-out;
      }
    }
    .activeTab {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: var(--app-primary-color);
      border-radius: 42px;
      transition: all 0.3s ease-in-out;
    }
  }

  .title {
    width: 265px;
    text-align: center;
    margin: 0 auto;
    margin-top: 24px;
    h3 {
      margin-bottom: 17px;
      font-weight: 600;
      font-size: 24px;
      height: 48px;
    }

    .price {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-top: 20px;
      .currentPrice {
        font-size: 46px;
        font-weight: 600;
        line-height: 1;
      }
      .originalPrice {
        color: rgb(192, 192, 192);
        vertical-align: bottom;
        margin-left: 5px;
        text-decoration: line-through;
        -webkit-text-decoration-color: rgba(192, 192, 192);
        text-decoration-color: rgba(192, 192, 192);
        font-size: 20px;
      }
    }
  }
  .rights {
    width: 100%;
    height: 286px;
    background: #e7f2e9;
    border-radius: 6px;
    padding: 24px;
    margin-top: 20px;
    .rightsItem {
      display: flex;
      margin-bottom: 24px;
      align-items: flex-start;
      line-height: 1.5;

      .rightsIcon {
        font-size: 18px;
        color: var(--app-primary-color);
        stroke-width: 10px;
        padding-top: 5px;
      }
      .rightsText {
        margin-left: 21px;
        color: var(--app-text-60-color);
        font-size: 16px;
      }
    }
  }

  .payBtn {
    margin-top: 30px;
    height: 54px;
    background-color: var(--app-primary-color);
  }
}

@media screen and (max-width: 576px) {
}

